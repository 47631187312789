import React, { useState } from "react"
import styled, { css } from "styled-components"
import {
  Border,
  Top,
  Name,
  SparkleHeader,
  Cost,
  PricingHeading,
  ButtonMonthYear,
  Plan,
  Description,
  Bottom,
  Button,
  List,
  Container,
  Option,
  PricingToggle,
  Wrapper,
} from "./PricingStyling"
import { segmentAction } from "../hooks/useSegmentTrack"
import { Link } from "gatsby"

const Toggle = styled.button``
const ToggleContainer = styled.div`
  width: 46px;
  height: 25px;
  padding: 2px;
  margin: 0 0.75rem;
  border-radius: 40px;
  background: #465b78;

  &.active div {
    transform: translateX(100%);
  }
`
const ToggleSwitch = styled.div`
  transition: transform 0.3s ease 0s;
  width: 21px;
  height: 100%;
  border-radius: 50px;
  background: #fff;
  transform: translateX(0%);
`

const plans = [
  {
    key: 0,
    accentColor: "white",
    name: "Free",
    monthly: 0,
    yearlyMonthlyCost: 0,
    description: "Share content, get paid for products and services.",
    cta: "Select",
    list: [
      "Share links + content",
      "Sell unlimited products and services",
      "1 Appointment Type (1:1)",
      "2 Default FAQs",
      "10 texts messages/month",
      "100 contacts in CRM",
    ],
    transactionFeeMonthly: "5",
    transactionFeeYearly: "5",
  },
  {
    key: 1,
    accentColor: "var(--sparkle-green)",
    name: "Starter",
    monthly: 29,
    yearlyMonthlyCost: 25,
    description: "Optimize your customer engagement.",
    cta: "Select",
    listHeading: [
      "All ",
      <strong key="emphasis-site">Site</strong>,
      " features, and:",
    ],
    list: [
      "Schedule appointments",
      "Encourage reviews",
      "30 FAQs",
      "300 texts/month",
      "500 contacts in CRM",
    ],
    transactionFeeMonthly: "3",
    transactionFeeYearly: "3",
  },
  {
    key: 2,
    accentColor: "var(--sparkle-brightblue)",
    name: "Pro",
    monthly: 49,
    yearlyMonthlyCost: 45,
    description: "Save time and increase revenues!",
    cta: "Select",
    listHeading: [
      "All ",
      <strong key="emphasis-scheduler">Scheduler</strong>,
      " features, and:",
    ],
    list: [
      "Automatically answer FAQs with AI automation",
      "Automated upsell",
      "Unlimited Appointment Types",
      "Unlimited FAQs",
      "500 texts/month",
      "Unlimited contacts in CRM",
    ],
    transactionFeeMonthly: "2",
    transactionFeeYearly: "2",
  },
  {
    key: 3,
    accentColor: "var(--sparkle-yellow)",
    name: "Premium",
    monthly: 9,
    yearlyMonthlyCost: 8,
    description: "Grow your business with bookings.",
    cta: "Select",
    listHeading: [
      "All ",
      <strong key="emphasis-Starter">Starter</strong>,
      " features, and:",
    ],
    list: [
      "Schedule appointments (1:1 & Group)",
      "Encourage reviews",
      "10 FAQs",
      "100 texts/month",
      "250 contacts in CRM",
    ],
    transactionFeeMonthly: "4",
    transactionFeeYearly: "4",
  },
]

const notes = [
  "2% transaction fee on products + services sold via We Sparkle platform",
]

const Pricing = ({
  selected,
  onChange,
  noBtns,
  pricingModel,
  setPricingModel,
  monthly,
  setMonthly,
  noHeading,
  planName,
  showFeatureCallout,
}) => {
  return (
    <Wrapper>
      <PricingHeading>
        {!noHeading ? <h1>Pricing</h1> : <h2>{planName} Plan</h2>}
        <PricingToggle
          style={{
            margin: noHeading && "2rem 0",
          }}
        >
          <ButtonMonthYear
            color={"#fff"}
            className={!pricingModel ? "active" : ""}
          >
            Pay Monthly
          </ButtonMonthYear>
          <Toggle
            isToggled={!monthly}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              segmentAction.track("Homepage Pricing Cycle Change", {
                value: monthly ? "monthly" : "annual",
              })
              setMonthly(!monthly)
              setPricingModel(!pricingModel)
            }}
          >
            <ToggleContainer className={!monthly ? "active" : ""}>
              <ToggleSwitch />
            </ToggleContainer>
          </Toggle>
          <ButtonMonthYear className={pricingModel ? "active" : ""}>
            Pay Yearly
          </ButtonMonthYear>
        </PricingToggle>
      </PricingHeading>
      <Container>
        <PricingGrid>
          {plans.map(
            (
              {
                key,
                accentColor: color,
                name,
                monthly: mon,
                yearlyMonthlyCost: year,
                description,
                cta,
                listHeading,
                list,
                transactionFeeMonthly,
                transactionFeeYearly,
              },
              i
            ) => {
              const active = selected === key
              return (
                <PricingGridItem
                  key={i + name}
                  id={`grid-${name.toLowerCase()}`}
                >
                  <Option
                    color={color}
                    key={key}
                    className={active ? "active" : ""}
                    onClick={() => {
                      segmentAction.track("Homepage Payment Change", {
                        value: name,
                      })
                      onChange(key)
                    }}
                    style={name === "Free" ? { flexDirection: "row" } : {}}
                  >
                    <Border color={color} className={"pos-full"} />
                    <Top>
                      <span
                        style={{
                          opacity: active ? 1 : 0,
                          position: "absolute",
                          right: 15,
                          top: 15,
                          background: color,
                          width: 30,
                          height: 30,
                          color: "var(--sparkle-darkblue)",
                          fontWeight: "bold",
                          lineHeight: "30px",
                          textAlign: "center",
                          borderRadius: "100%",
                          transition: ".2s",
                          transform: active ? "" : "translateY(15px)",
                        }}
                      >
                        ✓
                      </span>
                      <SparkleHeader color={color}>Sparkle</SparkleHeader>
                      <Name>{name}</Name>
                      <Cost>
                        ${!pricingModel ? mon : year}
                        <span>/mo</span>
                      </Cost>

                      {/* </div> */}
                      <Plan color={color}>Plan</Plan>
                      <Description color={color}>{description}</Description>
                    </Top>
                    <Bottom>
                      {!noBtns ? (
                        <Button bg={color}>{active ? "Selected" : cta}</Button>
                      ) : (
                        <div style={{ height: "2rem" }} />
                      )}
                      <List>
                        {listHeading && <p>{listHeading}</p>}
                        {list.map((item, j) => (
                          <li key={item + j}>
                            <span style={{ marginRight: 5, color }}>✓</span>{" "}
                            {item}
                          </li>
                        ))}
                        <li key={`fee`}>
                          <span style={{ marginRight: 5, color }}>✓</span>{" "}
                          {!pricingModel
                            ? transactionFeeMonthly
                            : transactionFeeYearly}
                          % transaction fee (plus Stripe processing fee)
                        </li>
                      </List>
                    </Bottom>
                  </Option>
                </PricingGridItem>
              )
            }
          )}
        </PricingGrid>
      </Container>
      {showFeatureCallout && (
        <Link
          to="/pricing#features"
          onClick={() => {
            segmentAction.track(`Homepage Pricing Page Click`)
          }}
          className="show-feature-list"
        >
          View Full Feature List →
        </Link>
      )}
    </Wrapper>
  )
}

export default Pricing

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background-color: var(--sparkle-darkblue);
`

const PricingGridItem = styled.div`
  grid-column: span 3;

  // set the order of the grid item with prop key of 'free' to last
  order: ${(props) => (props.id === "grid-free" ? 4 : null)};

  @media screen and (min-width: 720px) {
    // set the order of the grid item with prop key of 'free' to last & span across 3 columns on larger screens
    ${(props) => {
      switch (props.id) {
        case "grid-free":
          return css`
            grid-column: span 3;
            order: 4;
          `
        default:
          return css`
            grid-column: span 1;
          `
      }
    }}
  }
`
