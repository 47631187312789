import { useEffect } from "react"
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin"

const useKommunicate = ({
  appId = "3d37398243be6bd6f4fc3ad24f3b72824",
  settings = {
    popupWidget: true,
    automaticChatOpenOnNavigation: true,
    emojilibrary: true,
    onInit: () => {
      // once kommunicate is initialized, remove the "Chatbot by Kommunicate" footer
      // const css = `.mck-running-on notranslate vis{display:none !important; visibility:hidden !important;}`
      // window.Kommunicate.customizeWidgetCss(css)

      // replace the "Chatbot by Kommunicate" footer with "Chatbot by We Sparkle" since can't seem to remove div entirely
      const poweredBy =
        window.KommunicateGlobal.document.querySelector(".mck-powered-by")
      poweredBy.style.display = "none"
      poweredBy.style.visibility = "hidden"

      const wesparkle = window.KommunicateGlobal.document.createElement("span")
      wesparkle.innerHTML = "We Sparkle"

      // wesparkle.href = "https://wesparkle.co"
      // wesparkle.target = "_blank"
      // wesparkle.rel = "noopener noreferrer"

      // replace poweredBy with wesparkle
      poweredBy.parentNode.replaceChild(wesparkle, poweredBy)
    },
  },
} = {}) => {
  useEffect(() => {
    let timer
    if (appId && settings) {
      timer = setTimeout(() => {
        Kommunicate.init(appId, {
          ...settings,
          appSettings: { ...settings },
        })
      }, 2000)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [appId, settings])
}

export default useKommunicate
