import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import IconSparkle from "./icons/IconSparkle"
import IconLink from "./icons/IconLink"
import { segmentAction } from "../hooks/useSegmentTrack"

const twinkle = keyframes`
  from {
    opacity: .3;
    transform: scale(.7) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
`

const Sparkle = styled(IconSparkle)`
  transform: translateZ(0);
  animation: 1s ${twinkle} both alternate infinite;
`

const FeaturedSparkleSite = styled.a`
  font-size: 0.9rem;
  color: #555;
  position: absolute;
  left: 50%;
  bottom: -40px;
  white-space: nowrap;
`

const sparklePlacement = [
  {
    animationDelay: "100ms",
    position: "absolute",
    fill: "var(--sparkle-green)",
    left: -20,
    top: -20,
    width: 22,
    height: "auto",
  },
  {
    animationDelay: "600ms",
    position: "absolute",
    fill: "var(--sparkle-red)",
    left: -28,
    top: 20,
    width: 15,
    height: "auto",
  },
  {
    animationDelay: "300ms",
    position: "absolute",
    fill: "var(--sparkle-blue)",
    left: -50,
    top: 35,
    width: 22,
    height: "auto",
  },
  {
    animationDelay: "600ms",
    position: "absolute",
    fill: "var(--sparkle-red)",
    right: 130,
    top: 15,
    width: 12,
    height: "auto",
  },
  {
    animationDelay: "900ms",
    position: "absolute",
    fill: "var(--sparkle-blue)",
    right: 105,
    top: -5,
    width: 22,
    height: "auto",
  },
  {
    animationDelay: "1100ms",
    position: "absolute",
    fill: "var(--sparkle-green)",
    right: 90,
    top: 18,
    width: 14,
    height: "auto",
  },
]

const mobile = {
  width: 350,
  height: 500,
  scale: 0.5,
}
const desktop = {
  width: 600,
  height: 500,
  scale: 0.5,
}

const imgStyles = {
  background: "#fff",
  pointerEvents: "none",
  transformOrigin: "0 0",
}

const borderRadius = 20
const border = 3

// PROD
const sites = [
  {
    title: "Heather Lee",
    src: "https://sprkl.es/heatherlee/?noTrack=true",
    url: "https://sprkl.es/heatherlee/",
    imgMobile: (
      <StaticImage
        src="./imgs/featured/heatherlee-mobile.png"
        alt="Featured Site: Heather Lee"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
    imgDesktop: (
      <StaticImage
        src="./imgs/featured/heatherlee-desktop.png"
        alt="Featured Site: Heather Lee"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
  },
  {
    title: "Eat, Bubbaleh!",
    src: "https://sprkl.es/eat-bubbaleh/?noTrack=true",
    url: "https://sprkl.es/eat-bubbaleh/",
    imgMobile: (
      <StaticImage
        src="./imgs/featured/eatbubbaleh-mobile.png"
        alt="Featured Site: Eat, Bubbaleh!"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
    imgDesktop: (
      <StaticImage
        src="./imgs/featured/eatbubbaleh-desktop.png"
        alt="Featured Site: Eat, Bubbaleh!"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
  },
  {
    title: "Ihotu Ali",
    src: "https://sprkl.es/ihotu-ali/?noTrack=true",
    url: "https://sprkl.es/ihotu-ali/",
    imgMobile: (
      <StaticImage
        src="./imgs/featured/ihotuali-mobile.png"
        alt="Featured Site: Ihotu Ali"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
    imgDesktop: (
      <StaticImage
        src="./imgs/featured/ihotuali-desktop.png"
        alt="Featured Site: Ihotu Ali"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
  },
  {
    title: "SJC Body Love",
    src: "https://sprkl.es/sjcbodylove/?noTrack=true",
    url: "https://sprkl.es/sjcbodylove/",
    imgMobile: (
      <StaticImage
        src="./imgs/featured/sjcbodylove-mobile.png"
        alt="Featured Site: SJC Body Love"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
    imgDesktop: (
      <StaticImage
        src="./imgs/featured/sjcbodylove-desktop.png"
        alt="Featured Site: SJC Body Love"
        imgStyle={{ borderRadius: borderRadius, height: "auto" }}
      />
    ),
  },
]

const FeaturedCarousel = () => {
  const [currentSite, setCurrentSite] = useState(0)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCurrentSite((siteNum) => (siteNum + 1) % sites.length)
    }, 4000)

    // cleanup the timer on unmount or when the component updates
    return () => clearTimeout(timerId)
  }, [currentSite])

  return (
    <>
      {sites.map((site, index) => {
        const { imgDesktop, imgMobile, url, title } = site
        return (
          <div
            key={index + title}
            style={{
              height: "auto",
              width: "auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                overflow: "visible",
                width: mobile.width,
                height: mobile.height * mobile.scale,
                visibility: index === currentSite ? "visible" : "hidden",
              }}
            >
              {sparklePlacement.map((style, i) => (
                <Sparkle style={style} key={i} />
              ))}

              <div
                style={{
                  width: mobile.width,
                  height: "auto",
                  transform: `scale(${mobile.scale}) translateZ(0)`,
                  boxShadow: "0 0 100px rgba(0,0,0,.1)",
                  borderRadius: borderRadius,
                  border: `${border / mobile.scale}px solid #efefef`,
                  ...imgStyles,
                }}
              >
                {imgMobile}
              </div>
              <div
                style={{
                  position: "absolute",
                  left: "40%",
                  top: "20%",
                  height: "auto",
                  width: desktop.width,
                  boxShadow: "0 0 100px rgba(0,0,0,.1)",
                  borderRadius: borderRadius,
                  transform: `scale(${desktop.scale}) translateZ(0)`,
                  border: `${border / mobile.scale}px solid #efefef`,
                  ...imgStyles,
                }}
              >
                {imgDesktop}
              </div>
              <FeaturedSparkleSite
                href={url}
                onClick={() =>
                  segmentAction.track("Homepage Sample Site Click", {
                    value: title,
                  })
                }
                target={"_blank"}
                rel="noreferrer"
                style={{
                  position: "absolute",
                  fontSize: 16,
                  paddingLeft: 30,
                  top: desktop.height * desktop.scale + 75,
                  visibility: index === currentSite ? "visible" : "hidden",
                  zIndex: 100,
                }}
              >
                See site{" "}
                <IconLink
                  style={{
                    width: 16,
                    marginLeft: 5,
                    height: "auto",
                    fill: "currentColor",
                  }}
                />
              </FeaturedSparkleSite>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default FeaturedCarousel
