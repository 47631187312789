import React, { useState } from "react"
import styled from "styled-components"
import SignUp from "../components/SignUp"
import { ButtonPrimary } from "./Buttons"
import EnterpriseSignUp from "../components/EnterpriseSignUp"

function PageFeatures() {
  const [isSmallBiz, setIsSmallBiz] = useState(true)
  return (
    <div id="home-solutions-header">
      <h2>Solutions</h2>
      {feats.map(({ name, color, intro, bullets = [], paras = [], img }, i) => {
        return (
          <Feature
            key={i}
            style={{ marginBottom: i === feats.length - 1 ? 0 : "" }}
          >
            <div>
              <h3 style={{ color, marginTop: "1rem", marginBottom: "1rem" }}>
                {name}
              </h3>
              <div className={"inner"}>
                <h4>{intro}</h4>
                <div className={"text"}>
                  <ul>
                    {bullets.map((item, j) => {
                      return (
                        <li key={j}>
                          <span style={{ marginRight: "1rem" }}>○</span>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                  {paras.map((text, k) => {
                    return <p key={k}>{text}</p>
                  })}
                </div>
              </div>
            </div>
            <div style={{ alignItems: "center", margin: "1rem" }}>{img}</div>
          </Feature>
        )
      })}
      {/* <Link
      href="/pricing#features"
      target={"_blank"}
      onClick={() => {
          segmentAction.track(`Features Homepage Click`)
      }}
      className="show-feature-list">View Full Feature List →</Link> */}
      {/* <SignUpContainer
      // style={{ marginBottom: i === feats.length - 1 ? 0 : "" }}
      >
        <h2>Claim your free site</h2>
        <SignUp />
      </SignUpContainer> */}
      <Quote>
        <p className="block-quote">
          <strong>
            Interested in We Sparkle developing other tech solutions for you?
          </strong>
        </p>
        <p>
          <strong>
            Email{" "}
            <HighlightLink color="white" href="mailto:info@wesparkle.co">
              {" "}
              <u>info@wesparkle.co</u>
            </HighlightLink>{" "}
            with your request
          </strong>
        </p>
      </Quote>
      <Container
        className={"signup"}
        style={{ marginTop: "3rem", marginBottom: "2rem" }}
      >
        <Col>
          <Row>
            <ButtonPrimary
              style={{
                background: !isSmallBiz ? "#fff" : "var(--sparkle-red)",
                border: "1px solid #fff",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
                color: !isSmallBiz ? "#000" : "#fff",
              }}
              onClick={() => setIsSmallBiz(true)}
            >
              Small Business
            </ButtonPrimary>

            <ButtonPrimary
              style={{
                background: isSmallBiz ? "#fff" : "var(--sparkle-red)",
                border: "1px solid #fff",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                color: isSmallBiz ? "#000" : "#fff",
              }}
              onClick={() => setIsSmallBiz(false)}
            >
              Support Orgs
            </ButtonPrimary>
          </Row>
        </Col>
      </Container>

      {isSmallBiz && (
        <div>
          <h3 style={{ marginTop: "0.55rem" }}>Small Businesses</h3>
          <p>Claim your free account here:</p>
          <SignUp />
        </div>
      )}

      {!isSmallBiz && (
        <div>
          <EnterpriseSignUp />
        </div>
      )}
    </div>
  )
}

export default PageFeatures

const feats = [
  {
    color: "var(--sparkle-brightblue)",
    name: "AI Assistant",
    intro:
      "Your AI Assistant can be customized to answer FAQs from your clients or customers 24/7.",
    bullets: [
      "Answer questions",
      "Schedule appointments",
      "Upsell your products/services",
      "Displayed on your website or Facebook page",
    ],
    img: (
      <div className={"img"}>
        <img src={"/Assistant.gif"} alt={"assistant"} borderRadius={"10%"} />
      </div>
    ),
  },

  {
    color: "var(--sparkle-yellow)",
    name: "Sparkle Site",
    intro:
      "Your Sparkle Site can be the landing page or link in bio tool where people can download your lead magnets or buy your products/services.",
    bullets: [
      "Offer freebies or lead magnets to build your email list",
      "Sell your products/services",
      "Share links to your content",
      "Receive tips via your virtual tip jar",
    ],
    paras: [],
    img: (
      <div className={"img"}>
        <img src={"/Site.gif"} alt={"site"} borderRadius={"10%"} />
      </div>
    ),
  },
  {
    color: "var(--sparkle-green)",
    name: "Sparkle Scheduler",
    intro:
      "Your Sparkle Scheduler makes it easy for people to book and pay for appointments, classes, webinars, or other events.",
    bullets: [
      "Appointments can be 1:1 or group sessions",
      "Offer free sessions or collect payments (including sliding scale option)",
      "Add team members to certain types of meetings",
      "Integrates with Google Calendar, Microsoft Outlook, and iCal",
    ],
    paras: [],
    img: (
      <div className={"img"}>
        <img src={"/Scheduler.gif"} alt={"scheduler"} borderRadius={"10%"} />
      </div>
    ),
  },
  {
    color: "var(--sparkle-green)",
    name: "Sparkle Directory",
    intro:
      "Your Sparkle Directory makes it easy for people to discover the businesses, organizations, or members of your network or community.",
    bullets: [
      "Showcase the businesses in your coworking space or chamber of commerce, vendors at your market, or clients that your organization serves",
      "Drive sales to the businesses in your community",
      "Make it easy for people to browse, save, sort, and filter through the businesses",
      "Mobile-friendly progressive web app available via any device",
    ],
    paras: [],
    img: (
      <div className={"img"}>
        <img src={"/Directory.gif"} alt={"directory"} />
      </div>
    ),
  },
  {
    color: "var(--sparkle-green)",
    name: "Sparkle GPT",
    intro:
      "✨COMING SOON✨ Sparkle GPT helps answer questions and carries seamless conversations with your customers.",
    bullets: [
      "Learns from documents and databases of your organization's choosing",
      "Updates in real time to provide the latest information to queries",
      "Understands the context of questions asked, and responds accordingly",
      "Provides answers accurately within the scope of your organization",
    ],
    paras: [],
    img: (
      <div className={"img"}>
        <img
          src={"/sparklegpt-demo.gif"}
          style={{ borderRadius: "0%" }}
          alt={"sparklegpt"}
        />
      </div>
    ),
  },
]

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: center;
  max-width: 95vw;

  .sparkles {
    display: none;
  }

  h1 {
    text-wrap: balance;
    font-size: 3.5rem;
    & p {
      font-size: 3.5rem;
    }
  }

  @media screen and (max-width: 900px) and (min-width: 700px) {
    .carousel {
      transform: scale(0.75);
      margin-bottom: 4.5rem;
    }
  }

  @media screen and (max-width: 699px) {
    p {
      font-size: 1.2rem;
    }
    .carousel {
      display: none;
    }
    .sparkles {
      display: inline-block;
      align-items: "center";
      position: relative;
      transform: scale(0.8);
    }
  }
`

const Feature = styled.article`
  display: grid;
  @media screen and (max-width: 600px) {
    display: block;
  }
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  }
  grid-template-columns: 60% auto;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  border-radius: 25px;
  padding: 10px;
  border: 1px solid #0a354f;
  background-color: #0a354f;
  margin-block: 2.5rem;
  ul {
    width: 100%;
  }

  h3 {
    margin-left: 2rem;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  img {
    object-fit: contain;
    width: 100%;
    border-radius: 15%;
  }
  .img {
    width: auto;
    height: auto;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: clamp(0.75rem, 1em, 1.1rem);
    margin-left: 2rem;
    ul {
      width: 100%;
    }
    li {
      display: flex;
      width: 100%;
      line-height: 1.4;
      margin-bottom: 0.75rem;
    }
    .text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;
      width: 100%;
      margin-top: 2em;
    }

    @media screen and (max-width: 600px) {
      .img {
        display: flex;
        justify-content: center;
        padding-inline: 0;
        order: -1;
      }

      ul,
      .img {
        width: 100%;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
      .inner {
        display: flex;
      }
    }
  }
`
export const Link = styled.a`
  display: block;
  margin-top: 3rem;
  font-weight: bold;
  color: white;
  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
`
const Quote = styled.blockquote`
  background: linear-gradient(
    87.54deg,
    rgba(239, 71, 101, 0.9) -6.39%,
    rgba(55, 144, 226, 0.85) 51.12%,
    rgba(37, 213, 161, 0.85) 98.07%
  );
  background-size: 400% 400%;
  border-radius: 10px;
  padding: 5rem 2rem;
  text-align: center;
  margin-top: 4rem;
  animation: gradient 8s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 900px) {
    text-align: left;
    padding: 2rem;
    p.quote-author {
      text-align: right;
    }
  }
  p.block-quote {
    font-size: 1.5rem;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 900px) {
      font-size: 1.25rem;
    }
  }
`
const HighlightLink = styled.a`
  color: ${(props) => props.color};
  & span {
    text-wrap: balance;
  }
`
const SignUpGrid = styled.div`
  display: flex;
  grid-template-columns: auto auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  gap: 5rem;
`
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-direction: row;
  flex-wrap: nowrap;
`

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
