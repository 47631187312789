import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import SignUp from "../components/SignUp"
import FeaturedCarousel from "./FeaturedCarousel"
import IconSparkle from "./icons/IconSparkle"
import { clamp } from "lodash"
import EnterpriseSignUp from "../components/EnterpriseSignUp"
import { ButtonPrimary } from "./Buttons"

export function PageCallToAction() {
  const [isSmallBiz, setIsSmallBiz] = useState(true)
  return (
    <>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",

            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "2rem",
            }}
          >
            <h1
              style={{
                textWrap: "balance",
                lineHeight: "1.1",
              }}
            >
              AI Assistants for{" "}
              <p
                style={{
                  color: "var(--sparkle-red)",
                }}
              >
                Entrepreneurs
              </p>
            </h1>
            {/* <div className={"sparkles"}>
              {sparklePlacement.map((style, i) => (
                <Sparkle style={style} key={i} />
              ))}
            </div> */}
            <div>
              <p>
                {" "}
                Customize your AI Assistant and other tools to answer client
                questions.
              </p>
              <p>
                {" "}
                Small businesses, start your free account below. Business
                support organizations, enter your email below to connect!
              </p>
              {/* sign up section */}
              {/*Refactor into its own component its also used in PageFeatures.js*/}
              <Container
                className={"signup"}
                style={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col>
                  <Row>
                    <ButtonPrimary
                      style={{
                        background: !isSmallBiz ? "#fff" : "var(--sparkle-red)",
                        border: "1px solid #e74b53",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        color: !isSmallBiz ? "#000" : "#fff",
                        width: "100%",
                        justifyContent: "center",
                      }}
                      onClick={() => setIsSmallBiz(true)}
                    >
                      <div style={{ display: "flex" }}>Small Business</div>
                    </ButtonPrimary>

                    <ButtonPrimary
                      style={{
                        background: isSmallBiz ? "#fff" : "var(--sparkle-red)",
                        border: "1px solid #e74b53",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        color: isSmallBiz ? "#000" : "#fff",
                        width: "100%",
                        justifyContent: "center",
                      }}
                      onClick={() => setIsSmallBiz(false)}
                    >
                      <div style={{ display: "flex" }}>Support Orgs</div>
                    </ButtonPrimary>
                  </Row>
                </Col>
              </Container>

              {isSmallBiz && (
                <div>
                  <h3 style={{ marginTop: "0.55rem" }}>Small Businesses</h3>
                  <p>Claim your free account here:</p>
                  <SignUp />
                </div>
              )}

              {!isSmallBiz && (
                <div>
                  <EnterpriseSignUp />
                </div>
              )}
            </div>
          </div>
          <div className="carousel" style={{ margin: "2rem" }}>
            <img
              src="/assistant-transparent.gif"
              alt="AI Assistant"
              width={"100%"}
              height={"100%"}
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default PageCallToAction

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-direction: row;
  flex-wrap: nowrap;
`

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: center;
  max-width: 95vw;

  .sparkles {
    display: none;
  }

  h1 {
    text-wrap: balance;
    font-size: 3.5rem;
    & p {
      font-size: 3.5rem;
    }
  }

  @media screen and (max-width: 900px) and (min-width: 700px) {
    .carousel {
      transform: scale(0.75);
      margin-bottom: 4.5rem;
    }
  }

  @media screen and (max-width: 699px) {
    h1 {
      text-wrap: balance;
      font-size: 3rem;
      & p {
        font-size: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
      display: flex;
    }
    .carousel {
      display: none;
    }
    .sparkles {
      display: none;
    }
    .signup {
      margin-top: 2rem;
      display: inline-block;
      align-items: "center";
      position: relative;
      transform: scale(0.8);
    }
  }
`

const twinkle = keyframes`
  from {
    opacity: .3;
    transform: scale(.7) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
`

const Sparkle = styled(IconSparkle)`
  transform: translateZ(0);
  animation: 1.25s ${twinkle} both alternate infinite;
`

const sparklePlacement = [
  {
    animationDelay: "900ms",
    position: "relative",
    fill: "var(--sparkle-blue)",
    // left: 20,
    // top: 35,
    width: 90,
    height: "auto",
  },
  {
    animationDelay: "200ms",
    position: "relative",
    fill: "var(--sparkle-green)",
    left: -20,
    top: -15,
    width: 42,
    height: "auto",
  },
  {
    animationDelay: "600ms",
    position: "relative",
    fill: "var(--sparkle-red)",
    // left: 80,
    // top: 10,
    width: 40,
    height: "auto",
  },
]
