import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"

import { segmentAction, useSegmentTrackLoad } from "../hooks/useSegmentTrack"
import useSignUpContext from "../hooks/useSignUpContext"

import PageAbout from "./PageAbout"
import PageCallToAction from "./PageCallToAction"

import PageEnterprise from "./PageEnterprise"
import PageFeatures from "./PageFeatures"
import PageModalPlan from "./PageModalPlan"

import PageTestimonials from "./PageTestimonials"
import Pricing from "./Pricing"

import SignUp from "../components/SignUp"

import { ButtonPrimary } from "./Buttons"

import "./layout.css"

const Page = () => {
  useSegmentTrackLoad("Homepage")

  const {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    businessType,
    setBusinessType,
  } = useSignUpContext()

  return (
    <>
      <Section light>
        <Content>
          <PageCallToAction />
        </Content>
      </Section>
      <Section>
        <Content style={{ position: "relative", overflow: "visible" }}>
          <StaticImage
            placeholder={"none"}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              opacity: 1,
              transform: "translateY(-25%)",
              background: "transparent",
            }}
            imgStyle={{
              width: 539,
              height: 355,
              background: "transparent",
            }}
            alt={""}
            src={"./imgs/sparkle-cluster.png"}
          />
          <PageFeatures />
        </Content>
      </Section>
      <Section>
        <Content>
          <h2>Testimonials</h2>
          <PageTestimonials />
          <PageEnterprise />
        </Content>
      </Section>
    </>
  )
}

export default Page

const Section = styled.section`
  line-height: 1.3;
  font-size: 1.25rem;
  position: relative;

  /* top: 75px; */
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }

  p {
    margin-bottom: 0.75rem;
  }
  @media screen and (max-width: 900px) {
    position: relative;
    /* top: 75px; */
    font-size: 1rem;
    line-height: 1.4;
    padding-inline: 0.25rem;
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-darkblue);
        color: #fff;
    `
  }}
  ${({ light }) => {
    if (light)
      return `
        background: var(--sparkle-white);
        color: var(--sparkle-darkblue);
        `
  }}
`

const Content = styled.div`
  padding: 4rem var(--content-pad);
  max-width: 1000px;
  margin: auto;
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
    max-width: 900px;
  }
`

const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`
