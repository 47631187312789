import React, { useEffect, useState } from "react"
import Seo from "../core/Seo"
import Homepage from "../homepage/Page"
import PromoHomePage from "./promo"
import useKommunicate from "../homepage/useKommunicate"

const IndexPage = ({ location }) => {
  useKommunicate()
  const [isPromo, setIsPromo] = useState(false)

  useEffect(() => {
    // if has query string param promo, redirect to promo home page
    let params = new URLSearchParams(location.search)
    if (params.has("promo")) {
      setIsPromo(true)
    }
  }, [])

  return (
    <>
      <Seo
        title="We Sparkle • Simple Tools for Solopreneurs"
        includeZohoTracking
      />
      {isPromo ? <PromoHomePage /> : <Homepage />}
    </>
  )
}

export default IndexPage
