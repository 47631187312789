import React from "react"
import Seo from "../core/Seo"

const PromoHomePage = () => {
  return (
    <>
      <Seo title="We Sparkle • Promo" includeZohoTracking />
      <div style={{ padding: "50px" }}>
        <iframe
          src="http://localhost:3333/onboarding"
          width="100%"
          height="800px"
          style={{ border: "none", borderRadius: "25px" }}
          allowtransparency="true"
        />
      </div>
    </>
  )
}

export default PromoHomePage
