import React, { useState } from "react"
import axios from "axios"
import { ButtonPrimary } from "../homepage/Buttons"
import styled from "styled-components"
import IconLoaderCircle from "../icons/IconLoaderCircle"
import { segmentAction } from "../hooks/useSegmentTrack"

const EnterpriseSignUp = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [success, setSuccess] = useState(false)
    const [inProgress, setInProgress] = useState(false)
  
    const onSubmit = (e) => {
      e.preventDefault()
      segmentAction.track("Enterprise Sign Up Form Submit")
      setInProgress(true)
      // If success show this stuff + clear out
      // api call
      const baseURL = process.env.GATSBY_SPARKLE_API_PATH
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE
      const api = axios.create({ baseURL })
      api
        .post(`NewsletterAddRecipient?code=${apiCode}`, {
          email,
          firstName,
          lastName,
          leadType:"Enterprise",
        })
        .then((response) => {
          setSuccess(true)
          setInProgress(false)
  
          // Keep this timeout around after call... reset to allow more newsletter sign-ups
          setTimeout(() => {
            setSuccess(false)
            setEmail("")
          }, 5000)
        })
        .catch(() => {
          // TODO: Handle error
          console.error("Enterprise Sign Up did not work...")
          setSuccess(false)
          setInProgress(false)
          setEmail("")
        })
    }
  
    return (
      <Form onSubmit={onSubmit}>
        <h3 style={{marginTop:"0rem"}}>Entrepreneurial Support Orgs</h3>
        <p style={{marginBottom:"1.5rem"}}>
            Submit your contact information below:
        </p>
        <label
          style={{
            pointerEvents: inProgress || success ? "none" : "",
            position: "relative",
            display: "inline-block",
          }}
          className="first-name"
        >
          <input
            type={"firstName"}
            name={"firstName"}
            placeholder={"First Name"}
            required
            onChange={(e) => {
              const value = e.target.value
              setFirstName(value)
            }}
            value={firstName}
          />
        </label>
        <label
          style={{
            pointerEvents: inProgress || success ? "none" : "",
            position: "relative",
            display: "inline-block",
          }}
          className="last-name"
        >
          <input
            type={"lastName"}
            name={"lastName"}
            placeholder={"Last Name"}
            required
            onChange={(e) => {
              const value = e.target.value
              setLastName(value)
            }}
            value={lastName}
          />
        </label>
        <label
          style={{
            pointerEvents: inProgress || success ? "none" : "",
            position: "relative",
            display: "block",
          }}
          className="email"
        >
          <input
            type={"email"}
            name={"email"}
            placeholder={"Email"}
            required
            onChange={(e) => {
              const value = e.target.value
              setEmail(value)
            }}
            value={email}
          />
          <ButtonPrimary
            type={"submit"}
            style={{
              pointerEvents: success || inProgress ? "none" : "",
              position: "absolute",
              right: 6,
              top: "50%",
              width: 40,
              height: 40,
              padding: 0,
              background: success ? "var(--sparkle-darkgreen)" : "",
              transform: "translateY(-50%)",
            }}
          >
            {/* Quick little arrow */}
            {!success && !inProgress && (
              <svg
                viewBox={"0 0 1 1"}
                stroke={"#fff"}
                strokeWidth={0.25}
                strokeLinecap={"round"}
                style={{
                  overflow: "visible",
                  width: 9,
                  transformOrigin: "center",
                  transform: "rotate(-45deg)",
                }}
              >
                <path d={"M0,0 1,1"} />
                <path d={"M0,1 1,1"} />
                <path d={"M1,0 1,1"} />
              </svg>
            )}
            {/* Loading */}
            {inProgress && (
              <IconLoaderCircle
                style={{ marginLeft: 10 }}
                indicator={"#fff"}
                widthPercent={0.17}
                bg={"rgba(255,255,255,.5)"}
                length={20}
              />
            )}
            {/* Checkmark */}
            {success && (
              <svg
                viewBox={"0 0 1 1"}
                stroke={"#fff"}
                strokeWidth={0.25}
                fill={"none"}
                strokeLinejoin={"round"}
                strokeLinecap={"round"}
                style={{
                  overflow: "visible",
                  width: 12,
                }}
              >
                <path d={"M0,.7 .4,1 1,.2"} />
              </svg>
            )}
          </ButtonPrimary>
        </label>
        <span
      style={{
        opacity: success ? "1" : "0",
        transform: success ? "translateX(0)" : "translateX(5px)",
        transition: ".2s",
        display: "flex",
        position: "relative",
        fontSize: "1rem",
        justifyContent: "center"
      }}
        >
            We will be in touch soon ✨
        </span>
        
      </Form>
  )
}

export default EnterpriseSignUp

const Container = styled.div`
  color: #222222;
  font-size: 1.2rem;
  display: flex;
  > label {
    display: flex;
    position: relative;
  }
  .notification {
    height: 100%;
    position: absolute;
    display: flex;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    span {
      margin: auto;
    }
  }
  .text {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    pointer-events: none;
    height: 100%;
    position: absolute;
    font-weight: 700;
    margin: auto;

    z-index: 1;
  }
  input {
    width: 100%;
    padding-block: 0.65em;
    border-radius: 100px;

    background: #efefef;
    font-size: 1.2rem;
    font-weight: 300;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
    display: block;
    .text {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      pointer-events: none;
      height: 100%;
      position: absolute;
      font-weight: bold;
      margin: auto;

      z-index: 1;
    }
    input {
      margin-left: 0;
      margin-block: auto;
      width: 100%;
      padding-block: 0.5em;
    }
    .notification {
      right: 0.5rem;
    }
  }
  @media screen and (max-width: 900px) {
    .p {
      font-size: 1rem;
    }
  }
`

const ButtonArrowMovement = styled.div`
  font-family: "Tahoma";
  padding-block: 0.5em;
  /* @media screen and (max-width: 900px) {
    margin-left: 0px;
  } */
`
const Button = styled(ButtonPrimary)`
  font-size: 1.2rem;
  width: 100%;
  justify-content: center;
  margin-top: 0.25rem;

  :hover ${ButtonArrowMovement} {
    animation: none;
  }

  &[disabled] {
    background-color: #ccc;
    pointer-events: none;
  }
`

const Form = styled.form`
  margin-top: 0.5em;
  input {
    width: 100%;
    padding-block: 0.65em;
    padding-left: 1em;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: 300;
    display: block;
    background: #efefef;
    margin-bottom: 0.5em;
    ::placeholder {
      color: #6d6d6;
      font-weight: 300;
    }
    ::-webkit-input-placeholder {
      /* Edge */
      color: #999;
      font-weight: 300;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999;
      font-weight: 300;
    }
  }
  .first-name {
    width: 48%;
    margin-right: 4%;
  }
  .last-name {
    width: 48%;
  }
  @media screen and (max-width: 900px) {
    .email,
    .sparkle-site {
      width: 100%;
      margin: 0.25rem 0 0;
    }
    padding-block: auto;
    .p {
        font-size: 1rem;
    }
  }
`